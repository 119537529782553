import React from 'react'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import get from 'lodash/get'

import Layout from '../components/layout'
import Header from '../components/header'
import SEO from '../components/seo'
import Intro from '../components/sections/intro'
import PostsGrid from '../components/Posts/Grid'
import PostsPagination from '../components/Posts/Pagination'

const PostsByPageTemplate = ({
  data,
  location,
  pageContext: { page, nextPage, previousPage },
}) => {
  const posts = get(data, 'allContentfulPost.edges', [])

  return (
    <Layout phone="866-740-2901" location={location}>
      <SEO
        title="Medicare Supplement Plans - Medicare Companion"
        keywords={['medicare', 'insurance', 'plans', 'affordable', 'supplement']}
      />

      <Header phone="866-740-2901" hideCta />

      <Intro title={`Blog Page ${page}`} showSubTitle={false} colType="left-col" />

      <main id="main">
        <PostsGrid posts={posts} />

        <PostsPagination nextPage={nextPage} previousPage={previousPage} page={page} />
      </main>
    </Layout>
  )
}

export default PostsByPageTemplate

export const pageQuery = graphql`
  query($slugs: [String]) {
    allContentfulPost(
      filter: { slug: { in: $slugs } }
      sort: { order: DESC, fields: id }
    ) {
      edges {
        node {
          id
          title
          slug
          mainPhoto {
            file {
              url
            }
            fluid(maxWidth: 1200) {
              ...GatsbyContentfulFluid
            }
          }
          category {
            id
            slug
            title
          }
        }
      }
    }
  }
`
